// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use 'sass:meta';
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$depagne_app-primary: mat.define-palette(mat.$indigo-palette);
$depagne_app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$depagne_app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$depagne_app-theme: mat.define-light-theme(
  (
    color: (
      primary: $depagne_app-primary,
      accent: $depagne_app-accent,
      warn: $depagne_app-warn,
    ),
  )
);

// The following mixins include base theme styles that are only needed once per application. These
// theme styles do not depend on the color, typography, or density settings in your theme. However,
// these styles may differ depending on the theme's design system. Currently all themes use the
// Material 2 design system, but in the future it may be possible to create theme based on other
// design systems, such as Material 3.
//
// Please note: you do not need to include the 'base' mixins, if you include the corresponding
// 'theme' mixin elsewhere in your Sass. The full 'theme' mixins already include the base styles.
//
// To learn more about "base" theme styles visit our theming guide:
// https://material.angular.io/guide/theming#theming-dimensions
//
// TODO(v17): Please move these @include statements to the preferred place in your Sass, and pass
// your theme to them. This will ensure the correct values for your app are included.
@include mat.all-component-bases($depagne_app-theme);

/* You can add global styles to this file, and also import other style files */
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

@layer reset, primeng;

:root {
  --main-blue: #005091;
  --electric-blue: #0086c9;
  --middle-blue: #c0d7e3;
  --light-blue: #e6eff4;
  --not-yellow: #ffa75c;
  --not-red: #ff5c5c;
  --yellow: #ffbe35;
  --element: 13px;
  --element-tech: 30px;
  --element-h-tech: 120px;
  --b-error: #ffcbc0;
  --error: #ff3600;
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('assets/font/Inter-Regular.woff2?v=3.19') format('woff2'), url('assets/font/Inter-Regular.woff?v=3.19') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('assets/font/Inter-ExtraLight.woff2?v=3.19') format('woff2'), url('assets/font/Inter-ExtraLight.woff?v=3.19') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: bold;
  font-weight: 700;
  font-display: swap;
  src: url('assets/font/Inter-Bold.woff2?v=3.19') format('woff2'), url('assets/font/Inter-Bold.woff?v=3.19') format('woff');
}
@layer reset {
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif !important;
  }

  input {
    font-family: 'Inter', sans-serif !important;
  }

  /* HTML5 display-role reset for older browsers */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }

  body {
    line-height: 1;
  }

  ol,
  ul {
    list-style: none;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none;
  }
  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  button {
    cursor: pointer;
    user-select: none;
  }

  form {
    p {
      letter-spacing: 0.3;
      font-weight: 300;
      font-size: 15px;
      line-height: 19px;

      &.error {
        background-color: var(--b-error);
        color: var(--error);
        padding: 10px;
        border-radius: 5px;
        margin-top: 10px;
      }
    }
  }
}

.input-group {
  margin-top: 5px;
  position: relative;
  label {
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 11px;
    color: var(--main-blue);
  }

  input {
    height: 40px;
  }

  textarea {
    height: 120px;
    max-height: 120px;
    padding: 10px;
  }

  .required {
    color: var(--error);
  }
  &:before {
    position: absolute;
    top: 28px;
    left: 5px;
    content: 'invalide';
    color: var(--error);
    font-size: 10px;
    letter-spacing: 1px;
    z-index: 3;
    display: none;
  }
  &:has(input.ng-invalid.ng-touched) {
    &:before {
      display: block;
    }
  }
  input,
  textarea,
  select {
    font-family: 'inter';
    padding-left: 10px;
    margin-top: 3px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid var(--main-blue);
    color: black;
    width: 100%;
    max-width: 100%;
    font-weight: 300;
    font-size: 19px;
    height: 40px;
    background-color: white;

    &.ng-invalid.ng-touched {
      background-color: #fffaf9;
      color: var(--error);
      border: 1px solid var(--error);
      position: relative;
    }
    &:disabled {
      background-color: #e2e9ef;
      color: #396a82;
    }
  }

  select {
    text-transform: uppercase;
  }
}

.form-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.p-component {
  font-feature-settings: 'Inter';
  font-size: 13px;
  .p-icon-wrapper {
    svg {
      width: 13px;
    }
  }
}

.setting-container {
  position: relative;
  z-index: 2;
  background-color: white;
  width: 100%;
  min-height: 100vh;
  padding: 40px;

  h1 {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 20px;
    color: var(--main-blue);
  }
  .upload-group {
    background-color: aliceblue;
    padding: 10px;
    border-radius: 10px;
    &.mb {
      margin-bottom: 10px;
    }
    img {
      width: 80px;
      height: 80px;
    }
    .file-content {
      display: flex;
      align-content: flex-start;
    }
    .file-group {
      &.no-img {
        margin-left: 0;
      }
      margin-left: 20px;
      border-radius: 10px;
      padding: 10px;
      label {
        letter-spacing: 1px;
        text-transform: uppercase;
        font-size: 11px;
        color: var(--main-blue);
      }
      p {
        margin-top: 2px;
        span {
          opacity: 0.5;
        }
      }
    }
  }

  .introduction {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 15px;
    color: var(--main-blue);
  }

  .setting-column {
    max-width: 400px;
  }
  .upload-footer {
    display: flex;
    align-content: flex-start;
    margin-top: 10px;
    button {
      margin-left: 0px;
      margin-right: 5px;
    }
  }
  .form-footer {
    display: flex;
    align-content: flex-end;
  }

  .file-input {
    display: none;
  }

  .file-upload {
    .img-container {
      font-family: 'inter';
      padding: 10px;
      user-select: none;
      color: black;
      font-weight: 300;
      font-size: 13px;
      width: 100%;
      min-height: 30px;
      box-sizing: border-box;
      border-radius: 5px;
      border: 1px solid var(--main-blue);
      margin-bottom: 10px;

      img {
        width: 100%;
      }
    }
  }
  .suppr-group {
    margin-top: 20px;
    background-color: rgb(255, 249, 249);
    padding: 20px;
    border-radius: 10px;
    p {
      font-weight: 300;
      font-size: 15px;
      line-height: 19px;
      margin-bottom: 10px;
    }
    button {
      width: 100px;
      text-transform: uppercase;
      height: 30px;
      text-align: center;
      font-size: 11px;
      z-index: 5;
      border-radius: 5px;
      color: #ff3600;
      background-color: transparent;
      border: 1px solid #ff3600;
      transition: 0.3s;
      &:hover {
        background-color: #ff3600;
        color: white;
      }

      &:active {
        opacity: 0.8;
      }
    }
  }
}

.form-footer,
form {
  button {
    &.xl {
      width: 160px;
    }
    &.xxl {
      width: 200px;
    }
    width: 100px;
    margin-left: 5px;
    text-transform: uppercase;
    height: 30px;
    text-align: center;
    font-size: 11px;
    top: 40px;
    right: 40px;
    z-index: 5;
    border-radius: 5px;
    color: var(--main-blue);
    background-color: transparent;
    border: 1px solid var(--main-blue);
    transition: 0.3s;

    &.validation-button {
      &:disabled {
        border: 1px solid #e2e9ef;
        background-color: #e2e9ef;
        color: white;
        cursor: not-allowed;
      }

      background-color: var(--main-blue);
      color: white;
    }

    &:hover {
      background-color: var(--main-blue);
      color: white;
    }

    &:active {
      opacity: 0.8;
    }
  }
}
.p-datatable-header {
  border: none;
  border-radius: 10px 10px 0 0;
}
.p-paginator-page {
  min-width: 2rem;
  height: 2rem;
  margin: 0.143rem;
  transition: box-shadow 0.2s;
  border-radius: 10px;
  font-size: 12px;
}
.p-paginator-rpp-options {
  height: 44px;
  span {
    font-size: 12px;
  }
}
.p-datatable-wrapper {
  min-height: 200px;
}
.p-datatable .p-datatable-thead > tr > th {
  padding: 10px;
  min-width: 150px;
  color: var(--main-blue);
}
.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: var(--electric-blue);
}
.p-datatable .p-datatable-tbody > tr > td {
  padding: 20px 10px;
}
.flex-filter-table {
  width: 100%;
  display: flex;
  .filter-element {
    width: 240px;
    &.p {
      width: 260px;
    }
  }
  .p-dropdown {
    span {
      font-size: 10px;
      font-style: normal;
      text-transform: uppercase;
      color: var(--main-blue);
    }
    height: 44px;
    border: 1px var(--main-blue) solid;
    background-color: transparent;
  }
  .input-search {
    margin-right: 20px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      z-index: 2;
      height: 30px;
      top: 7px;
      min-width: 30px;
      mask-repeat: no-repeat;
      -webkit-mask-repeat: no-repeat;
      background-color: var(--main-blue);
      -webkit-mask-image: url(/assets/images/icon/recherche.svg);
      mask: url(/assets/images/icon/recherche.svg);
    }
    input {
      &::placeholder {
        color: var(--main-blue);
        opacity: 0.5;
      }

      position: relative;
      z-index: 1;
      box-sizing: border-box;
      margin-bottom: 5px;
      height: 100%;
      border: 1px var(--main-blue) solid;
      color: var(--main-blue);
      background-color: transparent;
      border-radius: 5px;
      font-style: normal;
      text-transform: uppercase;
      letter-spacing: 1px;
      text-align: left;
      font-size: 10px;
      width: 100%;
      padding-left: 30px;
      transition: linear 0.3s;
    }
  }
}

.mat-mdc-dialog-container {
  min-width: 500px !important;
  border-radius: 10px;
  max-height: 90vh !important;

  .modal {
    padding: 10px;
  }

  &.warning {
    background-color: #fff2f2;

    .modal-header {
      h1 {
        color: var(--warning);
      }
    }

    .modal-body {
      p {
        color: var(--warning);
      }
    }
  }

  .modal-header {
    display: flex;
    justify-content: space-between;

    h1 {
      text-transform: uppercase;
      font-weight: bold;
      color: var(--main-blue);
    }
  }

  .modal-body {
    .mtb {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    p {
      letter-spacing: 0.3;
      font-weight: 300;
      font-size: 15px;
      line-height: 19px;
    }
    .introduction {
      margin-top: 20px;
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 17px;
    }
    .group {
      &.mt {
        margin-top: 10px;
      }
      margin-bottom: 10px;
      padding: 10px;
      border-radius: 10px;
      background-color: rgb(237, 243, 245);
      .input-group {
        margin-top: 0px;
      }
      &.blocked {
        label {
          color: var(--error);
        }
      }
    }

    .input-group {
      margin-top: 5px;
      &.p-drop-group {
        .p-dropdown {
          margin-top: 10px;
          margin-bottom: 200px;
          width: 100%;
          .btn-filter {
            margin-left: 0px;
          }
          .p-inputgroup {
            display: block;
            input {
              margin-top: 5px;
              margin-bottom: 5px;
            }
          }
        }
      }
      label {
        display: block;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-size: 11px;
        color: var(--main-blue);
        width: 100%;
        min-width: 100%;
      }
      input {
        height: 40px;
      }
      .radio-group {
        display: flex;
        margin-top: 20px;
        input {
          min-width: 100px;
          width: 100px;
          height: 30px;
        }
        .radio-info {
          color: var(--main-blue);
        }
      }
      .modal-input textarea {
        height: 120px;
        max-height: 120px;
        padding: 10px;
      }

      input,
      textarea,
      select {
        font-family: 'inter';
        padding-left: 10px;
        margin-top: 3px;
        box-sizing: border-box;
        border-radius: 5px;
        border: 1px solid var(--main-blue);
        color: black;
        width: 100%;
        max-width: 100%;
        font-weight: 300;
        font-size: 19px;
        height: 40px;
        &:disabled {
          background-color: #e2e9ef;
          color: #396a82;
        }
      }

      select {
        text-transform: uppercase;
      }
    }
  }

  .modal-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

    button {
      letter-spacing: 1px;
      margin-left: 5px;
      text-transform: uppercase;
      width: 100px;
      height: 30px;
      text-align: center;
      font-size: 11px;
      top: 40px;
      right: 40px;
      z-index: 5;
      border-radius: 5px;
      color: var(--main-blue);
      background-color: transparent;
      border: 1px solid var(--main-blue);
      transition: 0.3s;

      &.validation-button {
        &:disabled {
          border: 1px solid #e2e9ef;
          background-color: #e2e9ef;
          color: white;
          cursor: not-allowed;
        }

        background-color: var(--main-blue);
        color: white;
      }

      &:hover {
        background-color: var(--main-blue);
        color: white;
      }

      &:active {
        opacity: 0.8;
      }
    }
  }
}

body:before {
  position: absolute;
  z-index: 10000;
  width: 100vw;
  height: 100vh;
  padding: 30px;
  box-sizing: border-box;
  background-color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 17px;
  line-height: 22px;
  content: "Le configurateur Depagne Beta n'est pas disponible sur mobile ou petit écran. Merci de consulter cette page sur ordinateur à la taille minimale de 700x600px.";

  @media screen and (min-height: 600px) and (min-width: 700px) {
    display: none;
  }

  @media print {
    display: none;
  }
}
.toggle-group,
.input-group .toggle-group {
  display: flex;
  align-items: center;
  .input-toggle {
    .toggle {
      padding-left: 0;
      margin-top: 0;
      box-sizing: border-box;
      border-radius: 0;
      border: none;
      color: black;
      width: auto;
      max-width: none;
      height: 0;
      position: absolute;
      &[type='checkbox'] {
        height: 0;
        width: 0;
        visibility: hidden;
      }

      &:checked + label {
        background: var(--b-error);
        border: 1px solid var(--error);
      }

      &:checked + label:after {
        left: calc(100% - var(--t-w));
        transform: translateX(-100%);
        background: var(--error);
      }
    }
    --t-size: 68px;
    --t-w: 2px;
    label {
      cursor: pointer;
      text-indent: -9999px;
      width: var(--t-size);
      height: calc(var(--t-size) / 2);
      border: 1px solid var(--main-blue);
      background: #fff;
      display: block;
      border-radius: calc(var(--t-size) / 2);
      position: relative;
    }
    label:after {
      content: '';
      position: absolute;
      top: var(--t-w);
      left: var(--t-w);
      width: calc((var(--t-size) / 2) - ((var(--t-w) * 2) + 2px));
      height: calc((var(--t-size) / 2) - ((var(--t-w) * 2) + 2px));
      background: var(--main-blue);
      border-radius: calc((var(--t-size) / 2) - ((var(--t-w) * 2) + 2px));
      transition: 0.3s;
    }
    label:active:after {
      width: 130px;
    }
  }
  .status {
    font-size: 16px;
    margin-left: 10px;
    color: var(--main-blue);
    &.blocked {
      color: var(--error);
    }
  }
}

@include meta.load-css('primeng/resources/themes/lara-light-blue/theme.css');
@include meta.load-css('primeng/resources/primeng.css');

// workaround to make sure background css graphics are printed
* {
  -webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
  color-adjust: exact !important;                 /* Firefox 48 – 96 */
  print-color-adjust: exact !important;           /* Firefox 97+, Safari 15.4+ */
}
